import React, { useState } from "react"
import { Chevron } from './Icons'

const Detail = ({ title, subtitle, open, className, children }) => {

	const [show, set] = useState(open)

	const handleClick = () => set(o => !o)

	return (
		<div className={className || "details"}>
			<div className="summary" onClick={handleClick}>
				<div className="flex-spaced">
					<span>{title}</span>
					{subtitle && <h3>{subtitle}</h3>}
				</div>
				<Chevron down />
			</div>
			{show && (
				<div className="inner">
					{children}
				</div>
			)}
		</div>
	)
}

export default Detail