export default function useProductSeo({ 
	title, 
	handle,
	description, 
	seo, 
	keywords = "",
	featuredImage, 
	priceRangeV2: {
		maxVariantPrice: {
			amount,
			currencyCode
		}
	},
	totalInventory,
	variants
}) {

	const metaTitle = seo?.title || `${title} | Harrys London`
	const metaDescription = seo?.description || description
	const metaImage = featuredImage?.src || ""
	const sku = variants?.[0]?.sku

	return {
		title: metaTitle,
		description: metaDescription,
		shareImage: metaImage,
		keywords: keywords?.value,
		type: 'product',
		price: { amount, currencyCode },
		richTextSnippet: `
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"url": "https://www.harrysoflondon.com/products/${handle}",
				"name": "${metaTitle}",
				"image": "${metaImage}",
				"description": "${metaDescription}",
				"sku": "${sku}",
				"brand": {
					"@type": "Brand",
					"name": "Harrys London"
				},
				"offers": {
					"@type": "Offer",
					"url": "https://www.harrysoflondon.com/products/${handle}",
					"priceCurrency": "${currencyCode}",
					"price": "${amount}",
					"itemCondition": "https://schema.org/NewCondition",
					"availability": "${totalInventory > 0 ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"}"
				}
			}
		`
	}
}

// Example
// const exampleSnippet = `
// 	{
// 		"@context": "https://schema.org/",
// 		"@type": "Product",
// 		"name": "${metaTitle}",
// 		"image": [
// 			"${metaImage || ""}",
// 		],
// 		"description": ${metaDescription},
// 		"sku": "0446310786",
// 		"mpn": "925872",
// 		"brand": {
// 			"@type": "Brand",
// 			"name": "Harrys London"
// 		},
// 		"review": {
// 			"@type": "Review",
// 			"reviewRating": {
// 				"@type": "Rating",
// 				"ratingValue": "4",
// 				"bestRating": "5"
// 			},
// 			"author": {
// 				"@type": "Person",
// 				"name": "Fred Benson"
// 			}
// 		},
// 		"aggregateRating": {
// 			"@type": "AggregateRating",
// 			"ratingValue": "4.4",
// 			"reviewCount": "89"
// 		},
// 		"offers": {
// 			"@type": "Offer",
// 			"url": "https://example.com/anvil",
// 			"priceCurrency": "USD",
// 			"price": "119.99",
// 			"priceValidUntil": "2020-11-20",
// 			"itemCondition": "https://schema.org/UsedCondition",
// 			"availability": "https://schema.org/InStock"
// 		}
// 	}
// `