import { useState } from 'react';
import { useStoreContext } from '../wrappers/Shop';

export const SIZE_SYSTEMS = ['uk', 'us', 'eu', 'cm']

export default function useSizeConversion() {

	const { userCurrency, sizeConversionTable } = useStoreContext()
	const [sizeSystem, setSizeSystem] = useState(
		userCurrency?.slug === 'uk' ? SIZE_SYSTEMS[0] : userCurrency?.slug === 'us' ? SIZE_SYSTEMS[1] : userCurrency?.slug === 'eu' ? SIZE_SYSTEMS[2] : SIZE_SYSTEMS[3]
	)

	const convertSize = (size, system) => {
		const validSize = sizeConversionTable.find(c => c.eu === size)
		return validSize ? validSize[system] : size
	}

	const getSize = (size, options = {}) => {
		const conversion = convertSize(size, sizeSystem)
		return conversion ? options?.withSystem ? `${conversion} (${sizeSystem})` : conversion : `${size} (EU)`
	}

	return {
		sizeSystem,
		setSizeSystem,
		getSize,
		convertSize
	}
}