// Dependencies
import React, { useRef } from "react"
import { useDraggable } from "react-use-draggable-scroll"
import { Link } from "gatsby"
import { useQuery } from "urql"
import { useStoreContext } from "../wrappers/Shop"
import CarouselPrompt from "../components/CarouselPrompt"
import { trackSelectItem } from "../components/useTrackingEvents"
import { getPrices } from "../components/PriceTag"
import { BRAND } from "../helpers/constants"

function useRecommendedProducts(productId) {
	const { filterProductsByCountry, localCountryCode } = useStoreContext()
	const RecommendationsQuery = `
		query RecommendedProducts($productId: ID!) @inContext(country: ${localCountryCode}) {
			productRecommendations(productId: $productId) {
				id
				title
				handle
				featuredImage {
					url
				}
				tags
				vendor
				variants(first: 1) {
					nodes {
						id
						title
						price {
							amount
							currencyCode
						}
						compareAtPrice {
							amount
							currencyCode
						}
						sku
					}
				}
			}
		}
	`
	const [result, redo] = useQuery({
		query: RecommendationsQuery,
		variables: { productId }
	})
	if (result?.error) console.error(result.error.message)
	return filterProductsByCountry(result?.data?.productRecommendations || [])
}

const Thumbnail = (props) => {

	const { cart } = useStoreContext()

	const {
		id,
		title,
		handle,
		featuredImage,
		variants,
		tags,
		vendor
	} = props
	
	const initialVariant = variants?.nodes?.[0]

	const handleClick = (e) => {
		trackSelectItem({
			cart: cart,
			item_list_id: `recommended-by-${handle}`,
			item_list_name: `Recommended by ${handle}`,
			listPathname: `/product/${handle}#recommended`,
			currencyCode: initialVariant?.price?.currencyCode,
			products: [
				{	
					id: initialVariant?.sku, // SKU
					name: title, // Product title
					brand: BRAND,
					category: vendor,
					variant: initialVariant?.title,
					price: initialVariant?.price?.amount,
					quantity: "1",
					list: `/product/${handle}#recommended`, // The list the product was discovered from or is displayed in
					product_id: id?.split('/Product/')?.[1], // The product_id
					variant_id: initialVariant?.id?.split('/ProductVariant/')?.[1], // id or variant_id
					compare_at_price: initialVariant?.compareAtPrice?.amount || "0.0", // "0.0" is ok 
					image: featuredImage?.url || "", // If available, otherwise use an empty string
				}
			]
		})
	}

	return (
		<Link to={`/products/${handle}`} onClick={handleClick} state={{ list: `/product/${handle}#recommended` }}>
			<div className="aspect-1x1 object-cover">
				<img src={featuredImage?.url || ""} />
			</div>
		</Link>
	)
}

const RecommendedProducts = ({ productId, productHandle }) => {

	const recommended = useRecommendedProducts(productId)

	// UI
	const swiper = useRef()
	const { events } = useDraggable(swiper)

	return (
		<div className="innerx2" style={{
			display: recommended && recommended.length > 0 ? 'block' : 'none'
		}}>
			<h2 className="innerx2 align-center page-gutters">Recommended products…</h2>

			<div className="carousel is-grab has-gaps innerx2 hide-scrollbars" {...events} ref={swiper}>
				{recommended?.map((product, index) => 
					<Thumbnail 
						{...product} 
						productHandle={productHandle} 
						index={index} 
						key={`${product.handle}${index}`}
					/>
				)}
			</div>

			<CarouselPrompt />
		</div>
	)
}

export default RecommendedProducts