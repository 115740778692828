// Dependencies
import React, { useEffect, useState } from "react"
import { graphql, Link, navigate } from "gatsby"
import PropTypes from "prop-types"
// Hooks & Helpers
import isLightOrDark, { LIGHT } from "../helpers/isLightOrDark"
import arrayToObject from "../helpers/arrayToObject"
import isSizeOption from '../helpers/isSizeOption'
import log from "../helpers/log"
// Wrappers
import Layout from "../wrappers/Layout"
import { useStoreContext } from "../wrappers/Shop"
// Components
import Details from "../components/Details"
import PriceTag, { getPrices } from "../components/PriceTag"
import ProductGallery from "../components/ProductGallery"
import RecommendedProducts from "../components/RecommendedProducts"
import useProductInfo from "../components/useProductInfo"
import useProductSeo from "../components/useProductSeo"
import Sections from "../components/Sections"
import useSizeConversion, { SIZE_SYSTEMS } from '../components/useSizeConversion'
import { 
	trackAddToCart, 
	useTrackViewItem, 
	getProductId, 
	getVariantId, 
	getCategoryFromTags 
} from '../components/useTrackingEvents'
import { BRAND } from "../helpers/constants"

const OtherOptions = ({ 
	name,
	values, 
	selectedOptions, 
	handleOptionSelect 
}) => {

	return (
		values.length > 1 ? (
			<Details title={name}>
				<div className="inner pdp-sizes">
					{values?.map(value => {
						const isSelected = value === selectedOptions[name]
						return (
							<button 
								className={isSelected ? 'active' : ''} 
								onClick={() => handleOptionSelect(name, value)}
								name={`${name}: ${value}`}
								key={value}
							>
								{value}
							</button>
						)
					})}
				</div>
			</Details>
		) : null
	)
}

const SizeOption = ({ 
	size, 
	name, 
	selectedOptions, 
	handleOptionSelect, 
	sizeSystem, 
	convertSize, 
	variant 
}) => {

	if (variant) {
		const convertedSize = convertSize(size, sizeSystem)
		const isSelected = size === selectedOptions[name]
		const isSoldOut = !variant.availableForSale && variant.inventoryPolicy === "DENY"
		
		return (
			<button 
				className={`${isSelected ? 'active' : ''} ${isSoldOut ? 'disable' : ''}`} 
				onClick={() => handleOptionSelect(name, size)}
				disabled={isSoldOut}
				name={`Size: ${size}`}
				style={{
					display: size < 40 && isSoldOut ? 'none' : 'inline-block'
				}}
			>
				{convertedSize}
			</button>
		)
	} else {
		return null
	}	
}

const ProductSizeOptions = ({ 
	name,
	values, 
	selectedOptions, 
	handleOptionSelect,
	optionWithVariants
}) => {

	const { sizeSystem, setSizeSystem, convertSize } = useSizeConversion()

	return (
		values.length > 1 ? (
			<div>
				<Details title={`Size (${sizeSystem.toUpperCase()})`} open>
					<div className="outer">
						{SIZE_SYSTEMS.map(system => (
							<button 
								type="button" 
								className={`a1 btn--small mr ${system === sizeSystem ? 'active' : ''}`} 
								key={system} 
								onClick={() => setSizeSystem(system)}
							>
								{system}
							</button>
						))}
					</div>
					<div className="inner pdp-sizes">
						{optionWithVariants?.map(({ value, variant }) => 
							<SizeOption 
								size={value} 
								name={name} 
								variant={variant}
								selectedOptions={selectedOptions} 
								handleOptionSelect={handleOptionSelect} 
								sizeSystem={sizeSystem}
								convertSize={convertSize}
								key={value} 
							/>
						)}
					</div>
				</Details>
			</div>
		) : null
	)
}

const ProductSwatchLink = ({ product, selected, state }) => {
	const { title, handle, productColourSwatch } = product
	const isLight = isLightOrDark(productColourSwatch) === LIGHT

	return (
		<Link 
			to={`/products/${handle}`} 
			className={`swatch ${selected ? 'active' : ''}`}
			style={{
				color: productColourSwatch || '#00FF00'
			}}
			state={state}
			title={title}
		>
			<div className="swatch-colour" style={{
				borderColor: !isLight ? 'currentColor' : '#CCCCCC'
			}} />
		</Link>
	)
}

const ProductColours = ({ 
	currentProductHandle, 
	relatedColours, 
	colourName,
	storeContext,
	listFromState
}) => {

	return (
		relatedColours?.length > 0 && (
			<Details title={'Colour'} subtitle={colourName} open>
				<div className="product-colour-swatches outer">
					{relatedColours.map(p => 
						p ? (
							<ProductSwatchLink 
								product={p} 
								selected={p.handle === currentProductHandle} 
								storeContext={storeContext}
								state={{ list: listFromState }}
								key={p.handle} />		
						) : null
					)}
				</div>
			</Details>
		)
	)
}

const ProductMaterials = ({ relatedMaterials, materialName }) => {

	return (
		relatedMaterials?.length > 0 && (
			<Details title={'Material'} subtitle={materialName?.value} open>
				{relatedMaterials.map(p => 
					<Link to={`/products/${p.handle}`} className="block" key={p.handle}>
						{p.productMaterialName ? (
							`${p.productMaterialName}`
						) : p.title}
					</Link>
				)}
			</Details>
		)
	)
}

const ProductCopyBlock = ({ html, title, ...props }) => (
	<Details title={title}>
		<div {...props} dangerouslySetInnerHTML={{__html: html}} />
	</Details>
)

const ProductCopyBlocks = ({ html }) => {
	const regex = /<ul\b[^>]*>([.\n\s\t>]*<li>.*<\/li>){0,}[.\n\s\t>]*<\/ul>/ig;
	const parse = regex.exec(html)
	const listElem = parse && parse[0]
	const remainder = html.replace(regex, '')
	return (
		html && listElem && remainder ? (
			<div className="outerx2 has-links u1">
				<ProductCopyBlock title="Product Details" html={listElem} />
				<ProductCopyBlock title="Product Description" html={remainder} />
			</div>
		) : html && listElem ? (
			<div className="outerx2 has-links u1">
				<ProductCopyBlock title="Product Details" html={listElem} />
			</div>
		) : html && remainder ? (
			<div className="outerx2 has-links u1">
				<ProductCopyBlock title="Product Description" html={remainder} />
			</div>
		) : (
			<div className="outerx2 has-links u1">
				<ProductCopyBlock title="Product Description" html={html} />
			</div>
		)
	)
}

function useVariant(variant) {

	const { 
		loading, 
		didJustAddToCart, 
		addLineItem 
	} = useStoreContext()
	
	// ---- USING INVENTORY LEVELS (TBC) ------
	// [NOTE] This may not be needed as they would be OK for products from the US to be shipped internationally anyway
	// const stockedAtShippingLocation = variant?.inventoryByLocation.find(item => userCurrency.shippingLocations.find(l => l === item.location.name && item.available > 0))
	// const inStock = stockedAtShippingLocation && stockedAtShippingLocation.available > 0
	// ---------

	const inStock = variant?.availableForSale
	const isPreorder = variant?.availableForSale && variant?.inventoryPolicy !== 'DENY'

	return {
		...variant,
		inStock,
		isPreorder,
		loading, 
		didJustAddToCart,
		// New cart stuff...
		addLineItem
	}
}

// --------------------------------------------------
// Add to cart button
const AddVariantToCart = ({ 
	productTitle, 
	shopifyId, 
	vendor, 
	variant, 
	price,
	hasNoStockOrPreorder,
	trackingData,
	deps = [] 
}) => {
	
	const { 
		title,
		inStock,
		addVariantsToCart,
		loading,
		didJustAddToCart,
		addLineItem
	} = useVariant(variant)
	
	const outOfStock = hasNoStockOrPreorder || variant && !inStock
	const [label, setLabel] = useState(loading ? 'Adding...' : didJustAddToCart ? 'Added!' : 'Add to cart')
	const [error, setError] = useState(null)

	useEffect(() => {
		if (loading) {
			setLabel('Adding...')
		} else if (error) {
			setLabel('Something went wrong...')
		} else if (didJustAddToCart) {
			setLabel('Added!')
		} else if (outOfStock) {
			setLabel('Out of stock')
		} else {
			setLabel('Add to cart')
		}
	}, [loading, didJustAddToCart, ...deps])

	const handleTracking = () => {
		trackAddToCart(trackingData)
	}

	const handleClick = () => {
		if (variant) {

			const line = trackingData.list ? {
				merchandiseId: variant.id,
				attributes: [{
					key: "Collection",
					value: trackingData.list
				}]
			} : {
				merchandiseId: variant.id,
			}

			addLineItem(line, (response, errors) => {
				if (response && !response.errors && !response.userErrors?.[0]?.message) {
					navigate('/cart', { state: { list: trackingData.list }})
					handleTracking()
				} else if (errors) {
					log(response)
					console.error(errors)
				} else if (response.userErrors?.[0]?.message) {
					log(response)
					console.error(response.userErrors)
					setError(response.userErrors?.[0]?.message)
				} else {
					log(response)
					console.error(response.errors)
					setError('Unknown error')
				}
			})
		} else {
			setLabel('Select a size first')
		}
	}

	return (
		<>
			<button 
				type="button" 
				className={`btn outerx4 ${outOfStock ? 'grey' : ''}`} 
				disabled={loading || outOfStock} 
				style={{ cursor: loading ? 'wait' : 'pointer'}} 
				onClick={handleClick}
			>
				{label}
			</button>
			{error && (
				<h3 className="outerx2 color-orange">
					{"⚠️ "}{error}
				</h3>
			)}
		</>
		
	)
}
function useProductState(options, variants) {
	// --- SELECTED OPTIONS/VARIANT
	const [selectedOptions, setSelectedOptions] = useState(
		arrayToObject(options, 'name', item => {
			return item.values.length < 2 ? item.values[0] : null
		})
	)
	const selectedVariant = variants?.find(v => {
		const doAllOptionsMatch = v.selectedOptions.reduce((flag, next) => {
			return selectedOptions[next.name] === next.value ? flag * 1 : flag * 0
		}, 1) ? true : false
		return doAllOptionsMatch
	})

	const handleOptionSelect = (key, value) => {
		setSelectedOptions(prev => {
			return {
				...prev,
				[key]: value
			}
		})
	}

	return {
		selectedOptions,
		selectedVariant,
		handleOptionSelect
	}
}

const ProductPage = ({ data, location }) => {

	const sections = data.settings?.nodes[0]?.defaultProductSections

	const productInfo = useProductInfo(data.product)

	const { 
		title, 
		handle,
		shopifyId,
		descriptionHtml,
		variants, 
		options,
		media,
		colourName,
		productColourName,
		productStyleName,
		productMaterialName,
		materialName,
		relatedMaterials,
		relatedColours,
		userCurrency,
		vendor,
		optionsWithVariants,
		hasNoStockOrPreorder,
		tags,
		totalInventory,
		cart
	} = productInfo

	const {
		selectedVariant,
		selectedOptions,
		handleOptionSelect
	} = useProductState(options, variants)

	const meta = useProductSeo(data.product)

	const initialVariant = selectedVariant || variants?.[0]
	const [initialVariantPricing] = getPrices(initialVariant?.priceV2, initialVariant?.compareAtPriceV2)
	
	// ANALYTICS
	const trackingData = {
		currencyCode: userCurrency?.currencyCode,
		list: location?.state?.list || "",
		cart: cart,
		products: [{
			id: initialVariant?.sku, // SKU
			name: title, // Product title
			brand: BRAND,
			category: vendor, // tags?.filter(t => !t.includes('Filter:') && !t.includes('Myles')).join(','),
			variant: initialVariant?.title,
			price: initialVariantPricing?.price?.amount,
			quantity: "1",
			list: location?.state?.list || "", // The list the product was discovered from or is displayed in
			product_id: getProductId(shopifyId), // shopifyId?.split('/Product/')?.[1], // The product_id
			variant_id: getVariantId(initialVariant?.shopifyId), // initialVariant?.shopifyId?.split('/ProductVariant/')?.[1], // id or variant_id
			compare_at_price: initialVariantPricing?.compareAtPrice?.value > 0 ? initialVariantPricing?.compareAtPrice.amount : "0.0", // If available 
			image: media?.[0]?.thumbnail?.images?.fallback?.src, // If available, otherwise use an empty string
			inventory: totalInventory
		}]
	}
	useTrackViewItem(trackingData, [selectedVariant?.shopifyId])

  return (
    <Layout {...meta} location={location}>
			<div className="grid gapsx2">
				<ProductGallery media={media} alt={`${title} - ${vendor}`} />
			
				<aside className="pdp-info page-gutters">
					<div className="flex-column-spaced fs">
						<div className="">
							<div className="outerx2 mbx4">
								{productStyleName && productMaterialName && productColourName ? (
									<>
										<h1>{productStyleName}</h1>
										<h2>{productMaterialName}, {productColourName}</h2>
									</>
								) : (
									<h1>{title}</h1>
								)}
								<h2><PriceTag variant={selectedVariant || variants[0]} userCurrency={userCurrency} /></h2>
							</div>
							
							<ProductMaterials 
								relatedMaterials={relatedMaterials} 
								materialName={productMaterialName}
							/>

							<ProductColours 
								relatedColours={relatedColours} 
								colourName={productColourName} 
								currentProductHandle={handle} 
								listFromState={location?.state?.list}
							/>
							
							{options.map(option => {
								return isSizeOption(option.name) ? (
									<ProductSizeOptions 
										{...option} 
										optionWithVariants={optionsWithVariants[option.name]}
										selectedOptions={selectedOptions} 
										handleOptionSelect={handleOptionSelect} 
										key={option.name} 
									/>
								) : (
									<OtherOptions 
										{...option} 
										selectedOptions={selectedOptions} 
										handleOptionSelect={handleOptionSelect} 
										key={option.name} 
									/>
								)
							})}

							<div className="outerx2">
								<AddVariantToCart 
									productTitle={title}
									shopifyId={shopifyId}
									price={initialVariantPricing?.price?.amount}
									vendor={vendor}
									variant={selectedVariant} 
									hasNoStockOrPreorder={hasNoStockOrPreorder}
									trackingData={trackingData}
									deps={[selectedOptions]} 
								/>

								{/* [NOTE] Might not need this TBC */}
								{/* {onlyInUK && !onlyInUS ? (
									<h4 className="outer">Not available in U.S.</h4>
								) : onlyInUS && !onlyInUK ? (
									<h4 className="outer">Available in U.S. only</h4>
								) : null} */}
							</div>
						</div>
						
						{descriptionHtml && <ProductCopyBlocks html={descriptionHtml} />}
					</div>
				</aside>
			</div>
			
			{/* Technogel section */}
			{vendor === 'Footwear' && (
				<Sections sections={sections} />
			)}
			
			<RecommendedProducts productId={shopifyId} />
    </Layout>
  )
}

ProductPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProductPage

export const query = graphql`
	fragment MediaImage on ShopifyProductImage {
		id
		# mediaContentType
		thumbnail: gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 1080)
		large: gatsbyImageData(layout: FULL_WIDTH)
	}

	# fragment MediaVideo on ShopifyVideo {
	# 	id
	# 	mediaContentType
	# 	preview {
	# 		image {
	# 			gatsbyImageData(layout: CONSTRAINED)
	# 		}
	# 	}
	# 	sources {
	# 		url
	# 		width
	# 		height
	# 	}
	# }

	fragment ProductMetafields on ShopifyProduct {
		metafields {
			key
			value
			relatedProducts {
				title
				handle
				tags
				metafields {
					key
					value
					relatedProducts {
						title
						handle
						tags
						metafields {
							key
							value
						}
					}
				}
			}
		}
	}

	fragment ProductLink on ShopifyProduct {
		shopifyId
		title
		handle
		tags
		vendor
		shopifyId
		...ProductMetafields
		media: images {
			...MediaImage
		}
		options {
			name
			values
		}
		variants {
			# id
			title
			shopifyId
			storefrontId
			availableForSale
			sku
			selectedOptions {
				name
				value
			}
		}
	}

	fragment ContentfulProduct on ContentfulProduct {
		spotlightMedia {
			file {
				url
			}
			large: gatsbyImageData(layout: FULL_WIDTH)
			thumbnail: gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 1080, quality: 80)
		}
	}

  query($handle: String!) {
		settings: allContentfulSettings(limit: 1) {
			nodes {
				defaultProductSections {
					...HomepageSection
				}
			}
		}
    product: shopifyProduct(handle: { eq: $handle }) {
      title
			handle
			shopifyId
			descriptionHtml
			vendor
			variants {
				sku
				title
        storefrontId
				shopifyId
        selectedOptions {
					name
          value
        }
				# presentmentPrices {
				# 	price {
				# 		amount
				# 		currencyCode
				# 	}
				# 	compareAtPrice {
				# 		amount
				# 		currencyCode
				# 	}
				# }
				availableForSale
      	inventoryPolicy
				sku
				# Created by a resolver...
				# [NOTE] This may not be needed as they would be OK for products from the US to be shipped internationally anyway
				# inventoryByLocation {
				# 	available # stock level
				# 	location {
				# 		name
				# 	}
				# }
      }
      options {
        name
        values
      }
			media: images {
				...MediaImage
			}
			# media {
			# 	... on ShopifyMediaImage {
			# 		...MediaImage
			# 	}
			# 	... on ShopifyVideo {
			# 		...MediaVideo
			# 	}
			# }
			tags
			...ProductMetafields
			description
			# seo {
      #   description
      #   title
      # }
			featuredImage {
				src: originalSrc
			}
			priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
			totalInventory
    }
  }
`