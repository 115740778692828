import arrayToObject from "../helpers/arrayToObject"
import { UK_ONLY_TAG, US_ONLY_TAG, useStoreContext } from "../wrappers/Shop"

// const FIELDS = [
// 	'product_colour_name', 
// 	'material_name',
// 	'product_style_name',
// 	'product_colour_swatch',
// 	'related_product_colours',
// 	'related_product_materials',
// 	'seo_keywords'
// ]

// [TODO] Make sure metafields on relatedProducts are resolved as well
export function sortMetafields(props) {	
	const getField = (key, fields) => fields?.find(f => f.key === key) || {}

	const resolveValues = (fields) => {

		const values = arrayToObject(fields, 'key', o => o.value)

		return {
			productStyleName: values['product_style_name'],
			productColourName: values['product_colour_name'], // getField('product_colour_name', fields)?.value,
			productMaterialName: values['material_name'], // getField('material_name', fields)?.value,
			productColourSwatch: values['product_colour_swatch'], // getField('product_colour_swatch', fields)?.value,
			styleid: values['styleid'], // getField('styleid', fields)?.value,
			seoKeywords: values['seo_keywords'], // getField('seo_keywords', fields)?.value,
		}
	}

	const resolveRelated = key => {
		return getField(key, props)?.relatedProducts?.filter(Boolean).map(p => {
			const { metafields, ...product } = p
			return {
				...product,
				...resolveValues(metafields)
			}
		}) || []
	}

	return {
		...resolveValues(props),
		relatedProductColours: resolveRelated('related_colours'),
		relatedProductMaterials: resolveRelated('related_materials'),
	}
}

export default function useProductInfo(productData) {
	const { filterProductsByCountry, userCurrency, resolveProductData, cart } = useStoreContext()

	const product = resolveProductData([productData], true) // true = isSingleEntry flag to return object rather than array

	const {
		productStyleName,
		productColourName,
		productMaterialName,
		productColourSwatch,
		relatedProductColours,
		relatedProductMaterials,
		seoKeywords,
		styleid
	} = sortMetafields(product?.metafields)

	const checkTags = tag => product?.tags?.find(t => t === tag) ? true : false
	const onlyInUS = checkTags(US_ONLY_TAG)
	const onlyInUK = checkTags(UK_ONLY_TAG)
	const relatedMaterials = filterProductsByCountry(relatedProductMaterials || []).filter(Boolean)
	const relatedColours = filterProductsByCountry(relatedProductColours || []).filter(Boolean)
	
	const optionsWithVariants = product?.options?.reduce((acc, item) => {
		const transformValues = item.values?.map(value => {
			const match = product.variants.find(v => v.selectedOptions.find(o => o.name === item.name)?.value === value)
			return {
				value: value,
				variant: match
			}
		})

		return {
			...acc,
			[item.name]: transformValues
		}
	}, {})

	const hasNoStockOrPreorder = product.totalInventory === 0 && product.variants[0].inventoryPolicy === "DENY"

	return {
		...product,
		productStyleName,
		productColourName,
		productMaterialName,
		productColourSwatch,
		availableInUS: true,
		availableInUK: true,
		onlyInUS,
		onlyInUK,
		relatedMaterials,
		relatedColours,
		userCurrency,
		optionsWithVariants,
		hasNoStockOrPreorder,
		seoKeywords,
		styleid,
		cart
	}
}