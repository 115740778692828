import React from "react"

const SYMBOLS = {
	"GBP": "£",
	"USD": "$",
	"EUR": "€"
}

// export function usePrice(variant, userCurrency) {
// 	// console.log(variant, userCurrency)
// 	const findPrice = code => variant?.presentmentPrices?.find(c => c.price.currencyCode === code)
// 	const pricing = findPrice(userCurrency?.currencyCode) || findPrice('GBP')
// 	const onSale = pricing?.compareAtPrice?.amount > 0 ? true : false
// 	return [pricing, onSale]
// }

export function getPrices(priceV2, compareAtPriceV2) {
	if (priceV2) {
		const pricing = {
			price: {
				...priceV2,
				value: parseInt(priceV2.amount, 10) || 0
			},
			compareAtPrice: compareAtPriceV2 ? {
				...compareAtPriceV2,
				value: parseInt(compareAtPriceV2, 10) || 0
			} : null
		}
		const onSale = pricing.compareAtPrice?.amount && parseInt(pricing.compareAtPrice?.amount, 10) > 0 ? true : false
		return [pricing, onSale]
	} else {
		return []
	}
}

const PriceTag = (props) => {
  const { variant } = props;
	const [pricing, onSale] = getPrices(variant.priceV2, variant.compareAtPriceV2)

	const formatPrice = (props) => {
		if (props) {
			const { amount, currencyCode } = props
			const symbol = SYMBOLS[currencyCode]
			return symbol ? `${symbol} ${parseInt(amount, 10)}` : `${parseInt(amount, 10)} ${currencyCode}`
		} else {
			return "…"
		}
	}

	const price = formatPrice(pricing?.price)
	const compareAtPrice = formatPrice(pricing?.compareAtPrice)

	return (
		pricing && onSale ? (
			<>
				<span className="color-orange"><s>{compareAtPrice}</s> </span>
				<span>{price}</span>
			</>
		) : pricing ? (
			<span>{price}</span>
		) : null
	)
}

export default PriceTag