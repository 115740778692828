// Dependencies
import React, { useEffect, useRef } from "react"
import { ShopifyMedia } from "../components/Media"
import { Chevron } from "../components/Icons"

const ProductGallery = ({ media, alt }) => {

	const el = useRef()

	useEffect(() => {
		let timer
		timer = setTimeout(() => {
			el.current.scrollTo(0, 0)
		}, 10)
		return () => clearTimeout(timer)
	}, [media])

	const handleNext = () => {
		const { height } = el.current.getBoundingClientRect()
		el.current.scrollLeft += height
	}
	const handlePrev = () => {
		const { height } = el.current.getBoundingClientRect()
		el.current.scrollLeft -= height
	}

	return (
		<div className="pdp-gallery">
			<div className="carousel hide-scrollbars" ref={el}>
				{media?.map((asset, i) => (
					<div key={asset.id}>
						{/* <Image image={asset.image?.large} proheight /> */}
						<ShopifyMedia {...asset} size="large" proheight alt={`${alt} - gallery - ${i+1}`} />
					</div>
				))}
			</div>

			<div className="pdp-gallery-controls">
				<button type="button" className="mr" onClick={handlePrev}><Chevron left stroke="#FFF" /></button>
				<button type="button" onClick={handleNext}><Chevron right stroke="#FFF" /></button>
			</div>
		</div>
	)
}

export default ProductGallery